var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.prevent)?_c('div',{staticClass:"simple-menu m-t-xs flex justify-between column full-width e-input-modern size1"},[_c('div',{staticClass:"stask-search stask-search-suporte"},[_c('e-row',{attrs:{"mr":""}},[_c('e-col',[_c('erp-s-field',{attrs:{"label":"Gerente"}},[_c('person-select',{model:{value:(_vm.filters.gerente),callback:function ($$v) {_vm.$set(_vm.filters, "gerente", $$v)},expression:"filters.gerente"}})],1)],1),_c('e-col',{staticStyle:{"min-width":"150px"}},[_c('date-input',{attrs:{"label":"Data (De)"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.container.load()}},model:{value:(_vm.filters.data1),callback:function ($$v) {_vm.$set(_vm.filters, "data1", $$v)},expression:"filters.data1"}})],1),_c('e-col',{staticStyle:{"min-width":"150px"}},[_c('date-input',{attrs:{"label":"Data (Até)"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.container.load()}},model:{value:(_vm.filters.data2),callback:function ($$v) {_vm.$set(_vm.filters, "data2", $$v)},expression:"filters.data2"}})],1),_c('e-col',{staticStyle:{"min-width":"150px"}},[_c('erp-s-field',{attrs:{"label":"Filtro Data"}},[_c('u-select',{staticClass:"no-box-shadow",attrs:{"hide-underline":"","inverted-light":"","options":[
                          {label: 'Cadastro', value: null},
                          /*{label: 'Contrato', value: 'contrato'},
                          {label: 'Início Produção', value: 'producao'},*/
                      ]},model:{value:(_vm.filters.tipoData),callback:function ($$v) {_vm.$set(_vm.filters, "tipoData", $$v)},expression:"filters.tipoData"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}},[_c('e-col',{staticStyle:{"min-width":"150px","max-width":"300px"}},[_c('erp-s-field',{attrs:{"label":"Contrato Assinado"}},[_c('u-select',{staticClass:"no-box-shadow",attrs:{"hide-underline":"","inverted-light":"","options":[
                          {label: 'Qualquer', value: null},
                          {label: 'Sim', value: true},
                          {label: 'Não', value: false},
                      ]},model:{value:(_vm.filters.contratoAssinado),callback:function ($$v) {_vm.$set(_vm.filters, "contratoAssinado", $$v)},expression:"filters.contratoAssinado"}})],1)],1)],1)],1),_c('e-row',{staticClass:"m-t",attrs:{"mr":""}},[_c('e-col',{staticClass:"text-right"},[_c('a',{staticClass:"m-r font-12 text-negative",on:{"click":_vm.reset}},[_vm._v("Remover filtros")]),_c('e-btn',{attrs:{"label":"Buscar"},on:{"click":_vm.buscarFechar}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }