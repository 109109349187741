const filters = {
    id: null,
    busca: null,
    gerente: null,
    data1: null,
    data2: null,
    tipoData: null,
    status: ['active', 'maintenance', 'suspended', 'homolog'],
    contratoAssinado: null,
    active: null,
}

export default filters
