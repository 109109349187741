import tableColumnsCache from "@/utils/tableColumnsCache"

//const listVersion = 0
const listVersion = Math.random()
export const listName = 'console.produtos.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 2},
    {label: 'Ativação R$', name: 'taxaInstalacao', active: true, sortable: true, ordering: 2},
    {label: 'Valor R$', name: 'valor', active: true, sortable: true, ordering: 2},
    {label: 'Cobrança', name: 'criterioCobranca', active: true, sortable: true, ordering: 2},
    {label: 'Forma Pagto', name: 'formaPagamento', active: true, sortable: true, ordering: 2},
    {label: 'Auto Contratação', name: 'visibilidade', active: true, sortable: true, ordering: 2},
], listVersion)
